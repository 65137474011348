<template>
  <v-container :style="`${customReports && customReports.style && `max-width: ${customReports.style.width}px`}`">
    <report-list v-if="isBaseRoute" />

    <div
      v-else-if="customReports.length > 0"
    >
      <connected-app
        v-for="(customReport, customReportIndex) in customReports"
        :key="customReportIndex"
        :name="customReport.customReportId"
        :custom-report="customReport"
      />
    </div>

    <div v-else>
      <span>Loading ...</span>
    </div>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapToolsGetters } = createNamespacedHelpers('tools')

export default {
  name: 'CustomReports',
  components: {
    ConnectedApp: () => import('src/custom-reports/components/ConnectedApp'),
    ReportList: () => import('src/custom-reports/components/ReportList'),
  },
  computed: {
    ...mapToolsGetters([
      'currentCustomReports',
    ]),
    customReports() {
      return this.currentCustomReports
    },
    isBaseRoute() {
      return this.$route.path === '/custom-reports'
    },
  },
}
</script>
